

























































import Vue from "vue";
import { EMAIL_REGEX } from "@/utils/valid";
//import AuthStore from "@/store/modules/AuthStore";
import AuthService from "@/api/AuthService";

export default Vue.extend({
  components: {},
  data() {
    return {
      dialogResetPassword: false,
      loading: false,
      token: "",
      email: "",
      password: "",
      password2: "",
      showPass1: false,
      showPass2: false,
      rules: [(v: string) => !!v || "Campo requerido"],
      passRules: [
        (v: string) =>
          v.length >= 8 || "La contraseña debe tener por lo menos 8 caracteres"
      ],
      emailRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) => EMAIL_REGEX.test(v) || "El correo no es válido"
      ]
    };
  },
  mounted() {
    this.token = (this.$route?.query.token ?? "") as string;
  },
  methods: {
    dialogChangeResetPassword(value: boolean) {
      this.dialogResetPassword = value;
    },
    login() {
      if ((this.$refs.login as Vue & { validate: () => boolean }).validate()) {
        this.loading = true;
        const authDto = { password: this.password, token: this.token };

        AuthService.updatePassword(authDto)
          .then(() => {
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 1500,
              icon: "success",
              title: "Contraseña",
              text: "Actualizada"
            }).then(() => {
              //this.$router.push("/");
              this.$router.push("/");
            });
          })
          .catch((err) => {
            //this.loading = false;
            let message = 'No se pudo actualizar';

            if (err?.response?.data?.message == 'token_expired') {
              message = 'Token expirado. Solicite un nuevo link para restablecerla';
            }

            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 1500,
              icon: "error",
              title: "Contraseña",
              text: message
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }

      //AuthStore.updateLoggedIn(true);
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.password2 || "Las contraseñas no coinciden";
    }
  }
});
