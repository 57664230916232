























import Vue from "vue";
import AuthService from "@/api/AuthService";

export default Vue.extend({
  components: {},
  data() {
    return {
      loading: false,
      token: "",
      verified: false,
    };
  },
  mounted() {
    this.token = (this.$route?.query.token ?? "") as string;
  },
  methods: {
    verify() {
      this.loading = true;

      AuthService.verifyToken({ token: this.token })
        .then(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 1500,
            icon: "success",
            title: "Mensaje del sistema",
            text: "Cuenta verificada. Puede iniciar sesión"
          }).then(() => {
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          });
        })
        .catch((err) => {
          let message = 'No se pudo verificar la cuenta';

          if (err?.response?.data?.message == 'token_expired') {
            message = 'Token expirado. Solicite un link para restablecer contraseña';
          }

          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 1500,
            icon: "error",
            title: "Mensaje del sistema",
            text: message
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
});
